$sm-breakpoint: 640px;
$md-breakpoint: 768px;
$lg-breakpoint: 1024px;
$xl-breakpoint: 1280px;
$xxl-breakpoint: 1536px;

$sm-multiplier:0.60;

$viewport-max: 68rem;
$small-padding: 1.5rem;

$primary-color: #C6FF24;
$secondary-text-color: #9DA2B1;
$active-color: #F5F5F5;
