#contacts {
    max-width: $viewport-max;
    width: 100%;
}

.contacts-container .first-col form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contacts-container .first-col form input {
    border: 1px solid #9DA2B1;
    color: black;
    width: 100%;
    padding: 0rem 1rem;
    //26px
    border-radius: 1.625rem;
    display: block;
}

.contacts-container .first-col form input.not-valid {
    border: 1.5px solid #dc2626;
}

.contacts-container .first-col form textarea {
    border: 1px solid #9DA2B1;
    color: black;
    width: 100%;
    padding: 0.325rem 1rem;
    border-radius: 1.625rem;
    display: block;
    resize: none;
}

#contacts form button.disabled{
    background-color:#e5e7eb;
}

.contacts-container .first-col form input::placeholder {
    color: black;
    opacity: 1;
    /* Firefox */
}

.contacts-container .first-col form input::placeholder::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: black;
}

.contacts-container .first-col form textarea::placeholder {
    color: black;
    opacity: 1;
    /* Firefox */
}

.contacts-container .first-col form textarea::placeholder::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: black;
}

.contacts-footer{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-right: 1rem;
}

#contacts iframe{
    width: 100%;
    //408px * 1,4
    height: 25.5rem;
    filter: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="g"><feColorMatrix type="matrix" values="0.3 0.3 0.3 0 0 0.3 0.3 0.3 0 0 0.3 0.3 0.3 0 0 0 0 0 1 0"/></filter></svg>#g');
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);    
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayScale=1);
}