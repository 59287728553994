.header-container{
    max-width: $viewport-max;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

#header-nav{
    display: none;
}

#header-nav a.active {
    background-color: $active-color;
    border-radius: 9999px;
    padding: 0.5rem 1rem 0.5rem 1rem;
}

#navbar-hamburger a.active{
    background-color: $active-color;
    display: block;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

#navbar-hamburger a{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

#navbar-hamburger a:hover{
    background-color: $active-color;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

#header-nav a {
    padding: 0.5rem 1rem 0.5rem 1rem;
}

#header-nav a:hover{
    background-color: $active-color;
    border-radius: 9999px;
    padding: 0.5rem 1rem 0.5rem 1rem;
}

#header-nav .dropdown{
    border-radius: 9999px;
    padding: 0.5rem 1rem 0.5rem 1rem;
}

#header-nav .dropdown:hover{
    background-color: $active-color;
    border-radius: 9999px;
    padding: 0.5rem 1rem 0.5rem 1rem;
}

#navbar-hamburger{
    max-height: 0;
    overflow: hidden;
    transition:all 0.5s ease-in-out;
}

#navbar-hamburger.is-open{
    max-height: 100%;
}

header li{
    //14px *1,4
    font-size: 1.225rem;
    //19px * 1,4
    line-height: 1.6625rem;
}

#hamburger-drop-down{
    max-height: 0;
    overflow: hidden;
    transition:all 0.5s ease-in-out;
}

#hamburger-drop-down.is-open{
    max-height: 14rem;
}

@media (min-width: $xxl-breakpoint) {
    #header-nav{
        height: 100%;
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }
}