//1366 - 1.4x to 1920
//1rem 16px

#main-banner h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: max-content;
}

.note {
    color: white;
    background-color: black;
    padding: 0.25rem 1.25rem;
    border-radius: 9999px;
    width: max-content;
}

.main-card.card-contact form input {
    background-color: #F5F5F5;
    color: black;
    width: 100%;
    padding: 0rem 1rem;
    border-radius: 9999px;
    display: block;
}

.main-card.card-contact form textarea {
    background-color: #F5F5F5;
    color: black;
    width: 100%;
    padding: 0.325rem 1rem;
    border-radius: 0.75rem;
    display: block;
    resize: none;
}

#contact-send-button:enabled:hover img{
    filter: brightness(0) invert(0);
}

#offer_1_button:hover img{
    filter: brightness(0) invert(1);
}

#offer_2_button:hover img{
    filter: brightness(0) invert(1);
}