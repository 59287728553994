//Font family
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

//Tailwind

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Urbanist', sans-serif;
        scroll-behavior: smooth; 
    }
}

/*main {
    min-height:  calc(100dvh - $header-height - $footer-height - 2.31875rem - 8rem);
}*/

button p {
    //15px * 1,4
    font-size: 1.3125rem;
    //20px * 1,4
    line-height: 1.75rem;
}

button {
    text-transform: uppercase;
    border-radius: 9999px;
}

button.end-icon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

a.end-icon{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    border-radius: 9999px;
}

input.not-valid{
    border:1.5px solid #dc2626;
}

input{
    border:1px solid transparent;
}

/*========== SCROLL BAR ==========*/

$hue-color:230 ; /*Blue 230*/
$scroll-bar-color: hsl($hue-color, 12%, 90%);
$scroll-thumb-color: hsl($hue-color, 12%, 80%);
$text-color-light: hsl($hue-color, 8%, 65%);

::-webkit-scrollbar{
    width: .60rem;
    background-color: $scroll-bar-color;
    border-radius: .5rem;
}

::-webkit-scrollbar-thumb{
    background-color: $scroll-thumb-color;
    border-radius: .5rem;
}

::-webkit-scrollbar-thumb:hover{
    background-color: $text-color-light;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    margin: auto;
  }

  @media (min-width: $lg-breakpoint) {
    .swiper-slide img{
        width: 75%;
    }
}


.swiper-pagination-bullet-active{
    background-color: $primary-color !important;
}