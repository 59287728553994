#instructors {
    max-width: $viewport-max;
}

.instructors-block{
    max-width: 75rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.instructor-badges{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center;
    margin-top: 0.5rem;
}

.instructor img{
    //256px * 1,4
    width: 16rem;
    //278px * 1,4
    height: 16.375rem;
    object-fit: cover;
    border-radius: 1.125rem;
    border: 2px solid transparent;
}

@media (min-width: $xl-breakpoint) {
    .instructor img{
        //256px * 1,4
        width:16rem;
        //278px * 1,4
        height: 17.375rem;
    }
}