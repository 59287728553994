footer {
    background-color: #F7F7F8;
    padding-left: $small-padding;
    padding-right: $small-padding;
}

.footer-container {
    max-width: $viewport-max;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
}

.footer-container .top-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
}

.footer-container .footer-points-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
}

#footer-nav {
    height: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

footer li {
    color: #9Da2B1;
}

.footer-container .bottom-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
}

.social-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.socials-list li {
    display: inline;
}

.footer-badge {
    color: white;
    background-color: #9DA2B1;
    padding: 0.25rem 0.5rem;
    border-radius: 1.25rem;
}

#footer-nav a:hover{
    color: black;
}

#socials a.social-wrapper:hover p{
    color: black;
}

#socials a.social-wrapper:hover img{
    filter: brightness(0) invert(0);
}

@media (min-width: $xxl-breakpoint) {
    footer {
        padding-left: 0rem;
        padding-right: 0rem;
    }

    .footer-container .top-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
    }

    .footer-container .footer-points-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;
    }

    #footer-nav {
        height: 100%;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}