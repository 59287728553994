#psychologist {
    max-width: $viewport-max;
}

#psychologist img {
    object-fit: cover;
    border-radius: 1.625rem;
    border: 1.25rem solid white;
}

#psychologist p {
    line-height: 1.6625rem;
}

#psychologist .second-row .first-col {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

#psychologist .contact {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
}

#psychologist .contact a:hover {
    color: $secondary-text-color
}

#psychologist .contact a:active {
    color: $secondary-text-color
}